import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import FairplayPolicyV2 from '../components/FairplayPolicy/FairplayPolicy'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { FAIRPLAY_POLICY as FAIRPLAYPOLICY_LINK } from '../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Fairplay Policy',
    url: FAIRPLAYPOLICY_LINK,
  },
]

const FairplayPolicy: React.FC = () => (
  <Layout
    showBreadcrumbs
    breadcrumbs={breadcrumbs}
    pokerOneLink={pokerOneLinksStaticPage}
    rummyOneLink={rummyOneLinksStaticPage}
  >
    <Helmet>
      {process.env.GATSBY_STAGING === 'true' && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
    <SEO
      title="Fairplay Policy"
      description="Read about the fairplay policy at Mega."
      organizationSchema={generateOrgSchema()}
      websiteSchema={generateWebsiteSchema()}
      breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
    />
    <FairplayPolicyV2 />
  </Layout>
)

export default FairplayPolicy
