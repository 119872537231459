import React from 'react'
import Container from 'react-bootstrap/esm/Container'

import './FairplayPolicy.scss'

const FairplayPolicy: React.FC = () => {
  return (
    <div id="fairplay-policy">
      <Container>
        <h1>GetMega Fairplay Policy</h1>
      </Container>
      <section>
        <Container>
          <p>
            GetMega emphasizes a safe and secure gaming environment for its users.
            Incidents of unfair and foul gaming tangents don't resonate with us.
            We're proud to offer one of the most genuine and secure online gaming
            platforms available. Let’s breakdown how we claim this:
          </p>
          <h2>Transparency</h2>
          <ol style={{ listStyleType: 'lower-alpha' }}>
            <li>
              The games offered here are fair and transparent. All players have
              equal access to information about the games, including the rules,
              odds, and payouts.
            </li>
            <li>
              To get 100% transparency each and every user here builds up their
              account with a valid contact information and email address.
            </li>
            <li>
              Without making a deposit, any player can view any cash table or
              tournament table. On a specific table, they may also view each
              player's hand history, game history, and noteworthy accomplishments.
            </li>
            <li>
              The champions of every promotion, competition, and series are
              featured on the app.
            </li>
            <li>
              We invite professional poker players to occasionally live stream
              their games and share their experiences as poker players. By watching
              these livestreams, users can learn about the game and the difficulties
              that professional poker players deal with on a regular basis.
            </li>
          </ol>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>Understand your opponents</h2>
          <ol>
            <li>
              Simply click on an opponent's profile while at any table to view their
              location, performance in the most recent tournaments, money cashed, and
              number of tournaments won.
            </li>
            <li>
              Recognize the playing style of your rivals. Every player on the platform
              has access to comprehensive opponent stats for free, allowing you to base
              your own play decisions on how they play.
            </li>
          </ol>
        </Container>
      </section>

      <section>
        <Container>
          <h2>Questionable Gaming Practices</h2>
          <p>
            GetMega is committed to providing a fair and secure gaming environment for
            all players. We achieve this by actively preventing unfair gameplay practices,
            some of which are detailed below:
          </p>
          <ul>
            <li>
              <b>Collusion:</b>This involves two or more players working together at the same
              table to share information about their cards and unfairly manipulate the outcome
              of hands.
            </li>
            <li>
              <b>Chip Dumping:</b>This occurs when a player intentionally loses chips to another
              player at the same table, potentially affecting the game's fairness.
            </li>
            <li>
              <b>Multiple Accounts:</b>Players are prohibited from creating or accessing accounts
              under different names, including those of family or friends. Sharing accounts is
              also strictly forbidden. Creating multiple accounts gives players an unfair advantage
              by allowing them to gather additional information during games.
            </li>
            <li>
              <b>IP Ghosting:</b>Attempting to change your IP address or location during a game
              without our authorization is considered a violation.
            </li>
            <li>
              <b>Software Modifications:</b>Tampering with the GetMega software by reverse-engineering,
              decompiling, modifying, or disassembling it is strictly prohibited.
            </li>
            <li>
              <b>External Assistance Programs:</b>Using external software that grants you an unfair
              advantage is not allowed. This includes, but is not limited to, Artificial Intelligence
              (AI) programs like:
            </li>
            <li>
              <b>Bots:</b>These automated programs play the game for you using pre-defined strategies.
              They analyze hand information, calculate actions based on the strategy, and execute them
              without your involvement. Bots exploit the lack of human emotions and fatigue in human
              players, creating an unfair advantage.
            </li>
            <li>
              <b>Real-Time Advisers (RTAs):</b>These powerful tools analyze active hands, run simulations,
              and recommend optimal plays in real-time. This provides players with an unbeatable advantage
              by offering solver solutions while playing. Even if detected, such tools pose a significant
              challenge due to their ability to recommend unexploitable plays.
            </li>
          </ul>
          <p>GetMega takes fair play seriously. We employ quick security measures and actively investigate
            suspected violations. If you encounter any suspicious activity, please report it to our support
            team. By working together, we can ensure a fun and fair gaming experience for everyone.</p>
        </Container>
      </section>

      <section>
        <Container>
          <h2>Safeguarding Your Game</h2>
          <ul>
            <li>
              <b>Team vigilance:</b>Our dedicated security team constantly monitors the platform to prevent
              unauthorized access and ensure fair gameplay. No one can tamper with the software or gain an
              unfair advantage.
            </li>
            <li>
              <b>Encrypted gameplay:</b>
              All your cards are encrypted during play. No one, including us, can see your hand until you reveal it.
            </li>
            <li>
              <b>Hand history tracking:</b>
              Every hand played on GetMega is assigned a unique ID. This allows us to retrieve and review
              any hand if needed. If you have concerns, contact us at [email.. ] with the Hand ID, date, or
              tournament details.
            </li>
            <li>
              <b>Secure login:</b>
              Your login password is encrypted and remains confidential. GetMega employees cannot access your password.
            </li>
            <li>
              <b>Automated detection:</b>
              We use advanced analytics to monitor player activity and detect suspicious patterns.
              This helps identify potential use of unauthorized tools like bots or Real-Time Assistance programs (RTAs).
              Players flagged for suspicious activity may undergo further checks to maintain a fair and fun environment.

            </li>
            <li>
              <b>Unauthorized applications:</b>
              While using the GetMega app, using any unauthorized applications that give players an unfair advantage is
              strictly prohibited. This includes tools like ICMIZER, PokerSnowie, GTO, Equilab, PioSolver, and MonkerSolver.
            </li>
          </ul>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>Payment Security</h2>
          <ul>
            <li>
              <b>Advanced Security:</b>
              Our payment gateway utilizes impeccable measures to safeguard your data during transactions.
            </li>
            <li>
              <b>Trusted Partners:</b>
              We collaborate with internationally recognized payment gateways known for their robust security standards.
            </li>
            <li>
              <b>Secure Storage:</b>
              GetMega doesn't store your credit card, bank account, or other sensitive financial details on our servers.
              If you choose to save this information for faster transactions, it's securely encrypted on our payment
              gateway's servers.

            </li>
            <li>
              <b>Dedicated Player Funds:</b>
              All player deposits are held securely in dedicated bank accounts. These funds are kept separate from
              company accounts and are not used for any investment activities.
            </li>
            <li>
              <b>2-Step Verification:</b>
              To further protect your account, we utilize two-factor authentication. This adds an extra layer of security
              by requiring a unique code in addition to your password when accessing your account.
            </li>
          </ul>
        </Container>
      </section>

      <section>
        <Container>
          <h2>RNG Certified</h2>
          <p>GetMega offers a truly random and fair gaming experience, just like playing at a real casino table. </p>
          <ul>
            <li>
              <b>Unpredictable Gameplay:</b>
              Our system utilizes a Random Number Generator (RNG), a sophisticated software that ensures every card
              dealt is completely random and independent of previous hands or other players. It's like having a virtual
              shuffle machine for each table, guaranteeing fairness.

            </li>
            <li>
              <b>Certified by Experts:</b>
              This RNG system is independently certified by internationally recognized companies like iTech Labs.
              Their rigorous testing confirms the fairness and security of our gameplay software, giving you peace of mind.
            </li>
            <li>
              <b>No Human Influence:</b>
              We have zero control over the RNG. It's completely automated and cannot be manipulated by anyone, ensuring
              a level playing field for all.
            </li>
            <li>
              Our services are not intended for or directed at persons under the
              age of eighteen (18). For any person who informs us that his age
              is eighteen (18) years or above, we reserve the right to access
              and verify the person&apos;s Personal Information, but the
              responsibility for not submitting any incorrect personal data
              remains with the user.
            </li>
            <li>
              <b>The RNG is the foundation of trust at GetMega:</b>
              It guarantees unpredictable outcomes, just like a real deck of cards, and prevents any player from having
              an unfair advantage. This ensures a fun and secure gaming experience for everyone.
            </li>
          </ul>
        </Container>
      </section>

      <section>
        <Container>
          <h2>Foul Language </h2>
          <p>GetMega strives to create a welcoming and enjoyable environment for all players.</p>
          <ul>
            <li>
              <b>Respectful Interactions:</b>
              We have a zero-tolerance policy for hate speech and abusive language. This behavior disrupts the fun
              for everyone and is strictly prohibited.
            </li>
            <li>
              <b>Reporting Abuse:</b>
              If you encounter any inappropriate behavior, you can easily report it to our team via [email..] We
              take all reports seriously and will investigate swiftly.

            </li>
            <li>
              <b>Friendly Atmosphere:</b>
              We encourage friendly conversation and sportsmanship at the tables. Let's keep it fun and respectful!
            </li>
            <li>
              <b>Consequences for Abusers:</b>
              We have a strict policy against abusive language and disruptive behavior. This may result in penalties,
              including account suspension or termination, depending on the severity of the offense.
            </li>
          </ul>
        </Container>
      </section>

      <section className="no-pad-top">
        <Container>
          <h2>Disconnection</h2>
          <p>
            We understand that internet disruptions can happen. Here's how we handle connection issues at GetMega:
          </p>
          <ul style={{ listStyleType: 'lower-alpha' }}>
            <li>
              <b>Extra Time to Reconnect:</b>
              If your connection drops during a game (disconnects or slows down), we'll give you extra time to
              reconnect to the table.

            </li>
            <li>
              <b>Auto-Fold and Sit-Out:</b>
              If you can't reconnect within the allotted time, we'll automatically fold your hand and set you
              to sit-out. This protects the game's pace for other players while allowing you to rejoin as soon
              as your connection stabilizes.
            </li>
            <li>
              <b>Time Limits Based on Stakes:</b>
              The higher the stakes of the game, the more time you'll get to reconnect. We prioritize fairness
              for all players, so reconnect times may be limited to keep the game moving.
            </li>
          </ul>
        </Container>
      </section>
    </div>
  )
}

export default FairplayPolicy
